import { useMemo } from "react";
import { useQuery } from "react-query";
import { GetIcons } from "utils/apis/courses";

const useIcons = () => {
  const { data, isLoading, isError, error } = useQuery(["icons"], GetIcons);

  return { iconsData: data?.data, isLoading, isError, error };
};

export default useIcons;
