// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import LoadingComponent from "examples/LoadingComponent";
import NotFoundComponent from "components/NotFoundComponent";

// Settings page components
import TabLayout from "examples/TabLayout";
import CourseCard from "examples/Cards/ProjectCards/CourseCard";

import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { GetSubjectsAndCourses } from "utils/apis/home";
import { useEffect, useMemo, useState } from "react";

import useStages from "hooks/useStages";
import MDButton from "components/MDButton";

import { GetSpecialCourses } from "utils/apis/courses";
import MDTypography from "components/MDTypography";
import NoDataImage from "assets/images/no-data.svg";
import { Box } from "@mui/material";

function StageDetails() {
  const { stagesData } = useStages();
  const [searchParams, setSearchParams] = useSearchParams();
  // const [activeTab, setActiveTab] = useState();
  const [selectedStageId, setSelectedStageId] = useState();

  const [specialCourseData, setSpecialCourseData] = useState();

  const setActiveTab = (value) => {
    setSearchParams({ stage: value });
  };

  const stages = useMemo(() => {
    return stagesData?.map((stageData) => stageData?.name);
  }, [stagesData]);

  useEffect(() => {
    if (stages?.length > 0 && !searchParams.get("stage")) {
      setSearchParams({ stage: stages[0] });
    }
  }, [stages]);

  // change stageId when active tab change
  useEffect(() => {
    if (searchParams && stagesData) {
      const stageParam = searchParams.get("stage");
      // Ensure stagesData is not empty
      if (stageParam && stagesData.length > 0) {
        const targetStage = stagesData.find((stageData) => stageData.name === stageParam);

        if (targetStage) {
          setSelectedStageId(targetStage.id);
        }
      }
    }
  }, [searchParams, stagesData]);

  const { data: specialCourses, isLoading } = useQuery(
    ["special-courses", selectedStageId],
    () => GetSpecialCourses(selectedStageId),
    {
      cacheTime: 0, // No caching, data is not kept in cache after unmount
      onSuccess: (data) => setSpecialCourseData(data?.data || []),
    }
  );

  // Sort the slides based on the sort_number property

  const sortedSpecialCourses = useMemo(() => {
    return specialCourseData?.sort((a, b) => a.sort_number - b.sort_number);
  }, [specialCourseData]);

  const handleDeleteLocal = (id) => {
    setSpecialCourseData((prevData) => prevData.filter((course) => course.id !== id));
  };

  const upperComponent = (
    <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mt={3}>
      <Link to="/courses/special-courses/add-new">
        <MDButton variant="gradient" color="info">
          اضافة كورس مميز
        </MDButton>
      </Link>
    </MDBox>
  );

  const renderSpecialCoursesContent = () => {
    if (isLoading || !sortedSpecialCourses) {
      return <LoadingComponent />;
    }

    if (sortedSpecialCourses?.length === 0) {
      return <NotFoundComponent />;
    }

    return (
      <Grid container spacing={2} sx={{ marginBlock: 1 }}>
        {sortedSpecialCourses?.map((specialCourse) => (
          <Grid item xs={12} md={6} xl={3} key={specialCourse?.id}>
            <CourseCard
              courseId={specialCourse?.id}
              onDelete={handleDeleteLocal}
              image={specialCourse?.wide_screen_image}
              stage={specialCourse?.stage}
              teacherName={specialCourse?.teacher_name}
              courseName={specialCourse?.name}
              numberOfLectures={specialCourse?.number_of_lectures}
              ratingsAverage={specialCourse?.average_rating}
              action={{
                type: "internal",
                route: `/course/details/${specialCourse?.course_id}`,
                color: "info",
                label: "تعديل الكورس",
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <TabLayout
      tabs={stages}
      setActiveTab={setActiveTab}
      activeTab={searchParams?.get("stage")}
      xlGridNumber={12}
      upperComponent={upperComponent}
    >
      {renderSpecialCoursesContent()}
    </TabLayout>
  );
}

export default StageDetails;
