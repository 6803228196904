/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prop-types */
import { createContext, useState } from "react";

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const savedUserString = localStorage.getItem("user");
  const savedUser = JSON.parse(savedUserString);
  console.log(savedUser, "TT");
  const userObj = savedUser ? savedUser : {};

  const [user, setUser] = useState(userObj);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export default UserContext;
