import { useEffect, useMemo, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";

import MDButton from "components/MDButton";
import { Box, Icon, Stack } from "@mui/material";
import Dropzone from "components/Dropzone";

import LectureCard from "./components/LectureCard";

function Lectures({ localState, setParentState, errors, getLecturesResults }) {
  // const [realLectures, setRealLectures] = useState([]);

  // useEffect(() => {
  //   if (getLecturesResults?.data?.length) {
  //     setRealLectures(getLecturesResults?.data);
  //   }
  // }, []);

  const handleChangeLectures = (event, index) => {
    const { name, value } = event.target;
    setParentState((prevState) => ({
      ...prevState,
      content: {
        ...prevState.content,
        lectures: [
          ...prevState.content.lectures.slice(0, index),
          { ...prevState.content.lectures[index], [name]: value },
          ...prevState.content.lectures.slice(index + 1),
        ],
      },
    }));
  };

  const setLocalStateLectureFile = (files, index) => {
    let file;
    if (files?.length > 0) {
      file = files?.[0];
      setParentState((prevState) => ({
        ...prevState,
        content: {
          ...prevState.content,
          lectures: [
            ...prevState.content.lectures.slice(0, index),
            { ...prevState.content.lectures[index], file },
            ...prevState.content.lectures.slice(index + 1),
          ],
        },
      }));
    }
  };

  const addNewLecture = () => {
    setParentState((prevState) => ({
      ...prevState,
      content: {
        ...prevState.content,
        lectures: [
          ...prevState.content.lectures,
          {
            sort_id:
              prevState.content.lectures && prevState.content.lectures.length > 0
                ? Number(
                    prevState.content.lectures[prevState.content.lectures.length - 1].sort_id
                  ) + 10
                : 10,
            title: null,
            description: null,
            video: null,
            file: null,
          },
        ],
      },
    }));
  };

  const addNewNestedLecture = (index) => {
    setParentState((prevState) => ({
      ...prevState,
      content: {
        ...prevState.content,
        lectures: [
          ...prevState.content.lectures.slice(0, index + 1),
          {
            sort_id:
              prevState.content.lectures && prevState.content.lectures.length > 0
                ? Number(prevState.content.lectures[index].sort_id) + 10
                : 10,
            title: null,
            description: null,
            video: null,
            file: null,
          },
          ...prevState.content.lectures.slice(index + 1),
        ],
      },
    }));
  };

  const deleteLecture = (index) => {
    setParentState((prevState) => ({
      ...prevState,
      content: {
        ...prevState.lectures,
        lectures: [
          ...prevState.content.lectures.slice(0, index),
          ...prevState.content.lectures.slice(index + 1),
        ],
      },
    }));
  };
  return (
    <MDBox>
      {/* {realLectures?.length > 0 && (
        <MDBox mb={3}>
          <MDTypography variant="h5">المحاظرات الفعلية</MDTypography>
          {realLectures.map((lecture) => (
            <MDBox mt={2} key={lecture?.id}>
              <LectureCard
                title={lecture?.title}
                description={lecture?.description}
                image="https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg"
                sortId="88"
                video="27868787"
              />
            </MDBox>
          ))}
        </MDBox>
      )} */}

      <MDBox>
        <MDBox mb={5}>
          <MDTypography variant="h5">المحاظرات المراد اضافتها</MDTypography>
          {localState?.lectures?.map((singleLecture, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item sm={12}>
                <MDBox>
                  <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      صورة الغلاف للمحاظرة
                    </MDTypography>
                  </MDBox>

                  <Dropzone
                    onFilesChange={(files) => setLocalStateLectureFile(files, index)}
                    initFiles={singleLecture?.file || null}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <MDBox mb={1} ml={0.5}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    التسلسل&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDInput
                  style={{ width: "100%" }}
                  multiline
                  name="sort_id"
                  onChange={(event) => handleChangeLectures(event, index)}
                  value={localState.lectures?.[index]?.sort_id}
                  error={errors?.[`lectures[${index}].sortId`]}
                />

                {errors?.[`lectures[${index}].sortId`] && (
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      {errors?.[`lectures[${index}].sortId`]}
                    </MDTypography>
                  </MDBox>
                )}
              </Grid>
              <Grid item xs={12} sm={3}>
                <MDBox mb={1} ml={0.5}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    العنوان&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDInput
                  style={{ width: "100%" }}
                  multiline
                  name="title"
                  onChange={(event) => handleChangeLectures(event, index)}
                  value={localState.lectures?.[index]?.title}
                  error={errors?.[`lectures[${index}].title`]}
                />

                {errors?.[`lectures[${index}].title`] && (
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      {errors?.[`lectures[${index}].title`]}
                    </MDTypography>
                  </MDBox>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDBox mb={1} ml={0.5}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    الوصف&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDInput
                  style={{ width: "100%" }}
                  multiline
                  name="description"
                  onChange={(event) => handleChangeLectures(event, index)}
                  value={localState.lectures?.[index]?.description}
                  error={errors?.[`lectures[${index}].description`]}
                />
                {errors?.[`lectures[${index}].description`] && (
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      {errors?.[`lectures[${index}].description`]}
                    </MDTypography>
                  </MDBox>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <MDBox mb={1} ml={0.5}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    معرف الفديو&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDInput
                  style={{ width: "100%" }}
                  multiline
                  name="video"
                  onChange={(event) => handleChangeLectures(event, index)}
                  value={localState.lectures?.[index]?.video}
                  error={errors?.[`lectures[${index}].video`]}
                />
                {errors?.[`lectures[${index}].video`] && (
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      {errors?.[`lectures[${index}].video`]}
                    </MDTypography>
                  </MDBox>
                )}
              </Grid>
              <Grid item sm={1.5}>
                <MDBox mb={1} ml={0.5}>
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ visibility: "hidden" }}
                  >
                    الرابط&nbsp;&nbsp;
                  </MDTypography>
                </MDBox>
                <MDButton variant="text" color="info" onClick={() => addNewNestedLecture(index)}>
                  <Icon>add_circle</Icon>
                </MDButton>
              </Grid>
              {index > 0 && (
                <Grid item xl={2}>
                  <MDButton variant="text" color="error" onClick={() => deleteLecture(index)}>
                    <Icon>delete</Icon>&nbsp;حذف
                  </MDButton>
                </Grid>
              )}
            </Grid>
          ))}
          <MDBox mt={3}>
            <MDButton variant="gradient" color="info" onClick={addNewLecture}>
              محاظرة اخرى
            </MDButton>
          </MDBox>
          {errors?.lectures && (
            <MDBox mt={1}>
              <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                {errors?.lectures}
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Lectures;
