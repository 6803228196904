import { Box, Grid, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import { Link } from "react-router-dom";

export default function StagesComponent({ stages }) {
  return (
    <>
      <MDTypography
        sx={{
          fontWeight: "bold",
          fontSize: "x-large",
          textAlign: "start !important",
        }}
      >
        المراحل الدراسية
      </MDTypography>
      <Grid container spacing={2} alignItems="center" mt={0.2}>
        {stages.map((s) => (
          <Grid item xs={6} sm={3} md={2} key={s.id}>
            <Link to={`/home/stages-and-subjects/${s.id}`}>
              <MDBox sx={{ position: "relative", height: "8rem", cursor: "pointer" }}>
                <MDBox
                  component="img"
                  src={s.image}
                  alt="stage"
                  sx={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "6px" }}
                />
                <MDBox
                  sx={{
                    position: "absolute",
                    bottom: "0.4rem",
                    left: "0.4rem",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{ maxWidth: "60%", padding: 1, borderRadius: "8px" }}
                    className="stage-overlay-black"
                  >
                    <MDTypography
                      sx={{
                        fontSize: "small",
                        color: "white !important",
                        fontWeight: "bold",
                        "@media only screen and (max-width: 400px)": {
                          fontSize: "x-small",
                        },
                      }}
                    >
                      {s.name}
                    </MDTypography>
                  </Box>
                </MDBox>
              </MDBox>
            </Link>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
