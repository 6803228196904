/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, Suspense } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Otis Admin PRO React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Otis Admin PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Otis Admin PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Otis Admin PRO React routes
import routes from "routes";

// Otis Admin PRO React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode,
  clearSuccessMSG,
  clearErrorMSG,
} from "context";

// Images
import logo from "assets/images/logo.png";

import { useSnackbar } from "notistack";

import RequireAuth from "./RequireAuth";
import SignInBasic from "layouts/authentication/sign-in";
import CreateSlide from "layouts/Home/Slides/create-slide";
import StageDetails from "layouts/Home/StagesAndSubjects/stage-details";
import CreateTeacher from "layouts/users/Teachers/create-teacher";
import CreateSpecialCourse from "layouts/courses/SpecialCourses/create-special-course";
import CourseDetails from "layouts/courses/CourseDetails";

import useAuth from "./hooks/useAuth";

export default function App() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    errorMSG,
    successMSG,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const notifySuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      onClose: () => {
        clearSuccessMSG(dispatch);
      },
    });
  };

  const notifyError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      onClose: () => {
        clearErrorMSG(dispatch);
      },
    });
  };

  useEffect(() => {
    if (errorMSG) {
      notifyError(errorMSG);
    }
    if (successMSG) {
      notifySuccess(successMSG);
    }
  }, [errorMSG, successMSG]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <React.Fragment key={route.route}>
            {/* public path */}
            {route.route == "/authentication/sign-in" ? (
              <Route exact path={route.route} element={route.component} key={route.key} />
            ) : (
              <Route element={<RequireAuth />}>
                <Route exact path={route.route} element={route.component} key={route.key} />
              </Route>
            )}
          </React.Fragment>
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && user?.access_token && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={logo}
              brandName="منصة مجتهد"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}

        <Routes>
          {getRoutes(routes)}
          <Route path="/authentication/sign-in" element={<SignInBasic />} />
          <Route element={<RequireAuth />}>
            <Route path="/home/slides/create" element={<CreateSlide />} />
            <Route path="/users/teachers/create" element={<CreateTeacher />} />
            <Route path="/courses/special-courses/add-new" element={<CreateSpecialCourse />} />
            <Route path="/home/stages-and-subjects/:id" element={<StageDetails />} />
            <Route path="/course/details/:id" element={<CourseDetails />} />
          </Route>

          <Route path="*" element={<Navigate to="/home/slides" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  );
}
