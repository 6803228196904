import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import DataTable from "examples/Tables/DataTable";

// Sales dashboard components
import ChannelsChart from "layouts/users/Teachers/components/ChannelsChart";

import dataTableData from "layouts/users/Teachers/data/dataTableData";
import useTeachers from "hooks/useTeachers";
import { Link } from "react-router-dom";
function Teachers() {
  const { teachersData } = useTeachers();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mt={3}>
        <Link to="/users/teachers/create">
          <MDButton variant="gradient" color="info">
            مدرس جديد
          </MDButton>
        </Link>
      </MDBox>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  المدرسين
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                {teachersData?.data && (
                  <DataTable
                    // table={{ ...dataTableData, rows: [] }}
                    table={{
                      columns: dataTableData?.columns,
                      rows: teachersData?.data.map((teacher) => ({
                        teacherDetails: teacher,
                        salesNumber: "--------------",
                        numberOfCourses: "----------------------------",
                        joinDate: "--------------",
                        actions: teacher,
                      })),
                      // rows: categories.data.map((category) => ({
                      //   id: category.category_id,
                      //   name: category.description[0]?.name || "no name",
                      //   image: category.image,
                      //   sortOrder: category.sort_order,
                      //   actions: category.category_id,
                      // })),
                    }}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Teachers;
