import React, { useCallback } from "react";
import { DropzoneArea } from "mui-file-dropzone";

const Dropzone = ({ initFiles, onFilesChange }) => {
  const handleChange = useCallback(
    (files) => {
      // Pass the files to the parent component
      if (files.length) {
        onFilesChange(files);
      } else {
        onFilesChange("");
      }
    },
    [onFilesChange]
  );

  return (
    <DropzoneArea
      //   initialFiles={initFiles?.map((image) =>
      //     // If image is a file (new product), return array of files, else return array of URLs
      //     image instanceof File ? image : null
      //   )}
      //   useChipsForPreview={true}
      initialFiles={initFiles ? initFiles : []}
      filesLimit={1}
      acceptedFiles={["image/jpg", "image/jpeg"]}
      dropzoneText="اسحب وافلت الملفات هنا"
      showAlerts={true}
      maxFileSize={1048576}
      onChange={handleChange}
      dropzoneClass="DropzoneArea-class"
      previewGridClasses={{
        container: "previewGrid-container-class",
        item: "previewGrid-item-class",
        image: "previewGrid-image-class",
      }}
    />
  );
};

export default Dropzone;
