import MDBox from "components/MDBox";

import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Icon } from "@mui/material";
import YesOrNoDialog from "examples/YesOrNoDialog";
import { useState } from "react";

import { DeleteTeacher, EditTeacher } from "utils/apis/teachers";
import { useMutation, useQueryClient } from "react-query";
import { useMaterialUIController, setSuccessMSG } from "context";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import IconUploader from "examples/IconUploader";
import MDInput from "components/MDInput";

const ActionsCell = ({ value }) => {
  const { id } = value;
  const [name, setName] = useState(value?.name);
  const [bio, setBio] = useState(value?.bio);
  const [imageFile, setImageFile] = useState(value?.image);

  // ** id is the category id **
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const queryClient = useQueryClient();
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  // const { mutate, isError, isLoading, error } = useMutation({
  //   mutationFn: DeleteTeacher,
  //   onSuccess: (response) => {
  //     setSuccessMSG(dispatch, "تمت عملية الحذف بنجاح");
  //     handleClose();
  //     queryClient.invalidateQueries({ queryKey: ["teachers"] });
  //   },
  // });

  // const deleteTeacher = () => {
  //   mutate(id);
  // };

  const { mutate, isError, isLoading, error } = useMutation({
    mutationFn: ({ teacherObj, teacherId }) => EditTeacher(teacherObj, teacherId),
    onSuccess: (response) => {
      setSuccessMSG(dispatch, "تمت عملية تعديل المعلومات بنجاح");
      handleCloseEdit();
      queryClient.invalidateQueries({ queryKey: ["teachers"] });
    },
  });

  const handleEdit = () => {
    const obj = { name, bio, file: imageFile };
    mutate({ teacherObj: obj, teacherId: id });
  };

  return (
    <>
      <MDBox sx={{ display: "flex", alignItems: "center" }}>
        {/* <Icon
          color="error"
          title="preview"
          fontSize="medium"
          sx={{ mx: 2, cursor: "pointer" }}
          onClick={() => setOpenDialog(true)}
        >
          delete
        </Icon> */}

        <Icon
          color="info"
          fontSize="medium"
          sx={{ cursor: "pointer" }}
          onClick={() => setOpenEditDialog(true)}
        >
          edit
        </Icon>
      </MDBox>
      {/* <YesOrNoDialog
        heading="هل انت متأكد من رغبتك في الحذف !؟"
        description=" لأنه لا يمكنك التراجع او استرجاع اي بيانات فيما بعد هل انت متأكد فعلا لا يمكنك التراجع فيما بعد لذلك ارجوك كن حذرا مع تلك العملية"
        functiontoDo={deleteTeacher}
        openDialog={openDialog}
        closeFunction={handleClose}
      /> */}
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEdit}
        PaperProps={{
          sx: {
            padding: 1.8,
          },
        }}
      >
        <DialogTitle>تعديل معلومات المدرس</DialogTitle>
        <DialogContent>
          <DialogContentText>يمكنك تعديل كافة المعلومات</DialogContentText>
          <MDBox mt={3} sx={{ minWidth: "480px" }}>
            <Grid container spacing={3} flexDirection="column">
              <Grid item xs={12}>
                <MDInput
                  type="text"
                  label="الأسم"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Grid>

              <Grid item xs={12}>
                <MDInput
                  type="text"
                  label="البايو"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  rows={2}
                  multiline
                  onChange={(e) => setBio(e.target.value)}
                  value={bio}
                />
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <IconUploader fileChange={setImageFile} defaultImage={imageFile} />
              </Grid>
              {isError && (
                <MDBox mt={2} mb={1}>
                  <MDTypography
                    variant="caption"
                    display="block"
                    color="error"
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    {error?.response?.data?.message}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => handleEdit()}
            disabled={isLoading}
          >
            تعديل
          </MDButton>
          <MDButton variant="gradient" color="secondary" onClick={() => handleCloseEdit()}>
            الغاء
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActionsCell;
