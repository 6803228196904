// useSubjects.js
import { useQuery } from "react-query";
import { GetSubjectsAndCourses } from "utils/apis/home";

const useSubjects = (id) => {
  const { data, isLoading, error } = useQuery(
    ["subjectsAndCourses", id],
    () => GetSubjectsAndCourses(id),
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
    }
  );

  return { subjectsAndCoursesData: data?.data, isLoading, error };
};

export default useSubjects;
