import { useMemo } from "react";
import { useQuery } from "react-query";
import { GetTeachers } from "utils/apis/teachers";

const useTeachers = () => {
  const { data: teachersData, isLoading, isError, error } = useQuery(["teachers"], GetTeachers);

  const teachers = useMemo(() => {
    if (teachersData?.data) {
      return teachersData.data.map((teacher) => ({
        id: teacher.id,
        name: teacher.name,
      }));
    }
    return [];
  }, [teachersData]);

  return { teachers, isLoading, isError, error, teachersData };
};

export default useTeachers;
