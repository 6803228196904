/* eslint-disable no-shadow */
import React, { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import MDInput from "components/MDInput";
import Stack from "@mui/material/Stack";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Dropzone from "components/Dropzone";
import { useMutation } from "react-query";
import { AddLecturesToCourse, EditLecturesOfCourse } from "utils/apis/courses";
import { useMaterialUIController, setSuccessMSG, setErrorMSG } from "context";

export default function LectureDialog({
  open,
  handleClose,
  refetch,
  courseId,
  type = "new",
  lecture,
}) {
  const [lectureImage, setLectureImage] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [video, setVideo] = useState(null);
  const [controller, dispatch] = useMaterialUIController();

  const {
    mutate: mutateForAddLectures,
    isError: isErrorForAddLectures,
    isLoading: isLoadingForAddLectures,
    error: errorForAddLectures,
  } = useMutation({
    mutationFn: ({ lecture, courseId }) => AddLecturesToCourse(lecture, courseId),
    onSuccess: (response) => {
      const { title: lectureName } = response.data;
      setSuccessMSG(dispatch, `تمت اضافة المحاظرة ${lectureName} بنجاح`);
      refetch();
      handleClose();
    },
    onError: (err) => {
      setErrorMSG(dispatch, "حدث خطأ أثناء عملية الأضافة");
    },
  });

  const {
    mutate: mutateForEditLectures,
    isError: isErrorForEditLectures,
    isLoading: isLoadingForEditLectures,
    error: errorForEditLectures,
  } = useMutation({
    mutationFn: ({ lecture, courseId }) => EditLecturesOfCourse(lecture, courseId),
    onSuccess: (response) => {
      const { title: lectureName } = response.data;
      setSuccessMSG(dispatch, `تمت عملية التعديل للمحاظرة ${lectureName} بنجاح`);
      refetch();
      handleClose();
    },
    onError: (err) => {
      setErrorMSG(dispatch, "حدث خطأ أثناء عملية التعديل");
    },
  });

  useEffect(() => {
    setLectureImage(lecture?.image || null);
    setSortOrder(lecture?.sort_id || null);
    setName(lecture?.title || null);
    setDescription(lecture?.description || null);
    setVideo(lecture?.video || null);
  }, [lecture]);

  const addLecture = () => {
    const imageFile = lectureImage?.[0] || null;
    const lecture = {
      sort_id: sortOrder,
      video,
      title: name,
      description,
      file: imageFile,
    };
    mutateForAddLectures({ lecture, courseId });
  };

  const editLecture = () => {
    const imageFile = lectureImage?.[0] || null;
    const lectureData = {
      id: lecture?.id,
      sort_id: sortOrder,
      video,
      title: name,
      description,
      file: imageFile,
    };
    mutateForEditLectures({ lecture: lectureData, courseId });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle id="add-images-dialog">
        {type == "new" ? "اضافة محاضرة جديدة" : "تعديل المحاضرة"}
      </DialogTitle>

      <DialogContent>
        <Stack spacing={2} sx={{ width: "500px !important", marginTop: 2 }}>
          <MDBox>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                صورة الغلاف للمحاظرة
              </MDTypography>
            </MDBox>

            <Dropzone
              onFilesChange={(file) => setLectureImage(file)}
              initFiles={lecture?.image ? [lecture?.image] : null}
            />
          </MDBox>
          <MDBox>
            <MDInput
              fullWidth
              label="التسلسل"
              onChange={(e) => setSortOrder(e.target.value)}
              type="number"
              value={sortOrder}
            />
          </MDBox>
          <MDBox>
            <MDInput
              fullWidth
              label="العنوان"
              onChange={(e) => setName(e.target.value)}
              type="text"
              value={name}
            />
          </MDBox>
          <MDBox>
            <MDInput
              fullWidth
              label="الوصف"
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              value={description}
            />
          </MDBox>
          <MDBox>
            <MDInput
              fullWidth
              label="رمز الفديو"
              onChange={(e) => setVideo(e.target.value)}
              type="text"
              value={video}
            />
          </MDBox>
          {isErrorForAddLectures && (
            <MDBox mt={2} mb={1}>
              <MDTypography
                variant="caption"
                display="block"
                color="error"
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {errorForAddLectures?.response?.data?.message}
              </MDTypography>
            </MDBox>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose}>اغلاق</MDButton>

        {type == "new" ? (
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => addLecture()}
            disabled={isLoadingForAddLectures}
          >
            اضافة
          </MDButton>
        ) : (
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => editLecture()}
            disabled={isLoadingForEditLectures}
          >
            تعديل
          </MDButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
