import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";
import { UserProvider } from "./context/UserProvider";
import "./main.css";

import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider, useSnackbar } from "notistack";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <SnackbarProvider maxSnack={3}>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <MaterialUIControllerProvider>
            <App />
          </MaterialUIControllerProvider>
        </UserProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  </BrowserRouter>
);
