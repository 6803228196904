import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Otis Admin PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function TabLayout({
  stickyNavbar,
  children,
  tabs,
  setActiveTab,
  activeTab,
  xlGridNumber = 10,
  upperComponent,
}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {upperComponent}
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12} xl={xlGridNumber}>
            <AppBar position="static">
              <Tabs orientation={tabsOrientation} value={activeTab} onChange={handleSetTabValue}>
                {tabs?.map((tab) => (
                  <Tab label={tab} key={tab} value={tab} />
                ))}
                {/* <Tab label="Messages" />
                <Tab label="Social" />
                <Tab label="Notifications" />
                <Tab label="Backup" /> */}
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

// Setting default values for the props of TabLayout
TabLayout.defaultProps = {
  stickyNavbar: false,
};

// Typechecking props for TabLayout
TabLayout.propTypes = {
  stickyNavbar: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TabLayout;
