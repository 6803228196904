// useCourseById.js
import { useQuery } from "react-query";
import { GetCourseById } from "utils/apis/courses";

const useCourseById = (id) => {
  const { data, isLoading, error } = useQuery(["courseDetails", id], () => GetCourseById(id), {
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
  });

  return { courseDetailsData: data?.data, isLoading, error };
};

export default useCourseById;
