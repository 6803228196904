import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import CreateNewCourse from "../components/CoursePreview/CreateNewCourse";
export default function NewCourse() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CreateNewCourse
        title="اضافة كورس جديد"
        description="سيتم اضافة هذا الكورس في النظام بعد اكمال كافة الخطوات اللازمة"
      />
    </DashboardLayout>
  );
}
