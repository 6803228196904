// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import { useMutation } from "react-query";

import { useMaterialUIController, setSuccessMSG, setErrorMSG } from "context";
import logo from "assets/images/logo.png";
// Otis Admin PRO React components

import { Box, CardMedia, Grid, Typography } from "@mui/material";
import { deleteLectureFromCourse } from "utils/apis/courses";
import YesOrNoDialog from "examples/YesOrNoDialog";
import { useEffect, useState } from "react";

function LectureCard({
  id,
  courseId,
  image,
  title,
  description,
  sortId,
  video,
  duration,
  editLectureFunction,
  onDeleteLecture,
}) {
  const editLectureClicked = () => {
    if (editLectureFunction) {
      editLectureFunction();
    }
  };

  const [controller, dispatch] = useMaterialUIController();
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const { mutate, isError, isLoading, error } = useMutation({
    mutationFn: ({ idOfCourse, lectureId }) => deleteLectureFromCourse(idOfCourse, lectureId),
    onSuccess: (response) => {
      setSuccessMSG(dispatch, "تمت عملية الحذف بنجاح");
      handleClose();
      onDeleteLecture(id);
    },
    onError: (err) => {
      setErrorMSG(dispatch, "حدث خطأ أثناء عملية الحذف");
    },
  });

  const handleDeleteLectureFromCourse = () => {
    mutate({ idOfCourse: courseId, lectureId: id });
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [showDefaultImage, setShowDefaultImage] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setShowDefaultImage(false), 400);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Card
      sx={{
        overflow: "hidden",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        boxShadow: "none",
        borderRadius: "4px",
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} md={4}>
          <Box sx={{ p: 1 }}>
            {showDefaultImage || !isLoaded ? (
              <CardMedia
                src={logo}
                onLoad={() => setIsLoaded(true)}
                component="img"
                sx={{
                  width: "100%",
                  height: "240px",
                  margin: 0,
                  boxShadow: ({ boxShadows: { md } }) => md,
                  objectFit: "fill",
                  objectPosition: "center",
                  opacity: showDefaultImage ? 1 : 0,
                  transition: "opacity 0.6s ease-in-out",
                }}
              />
            ) : null}

            <CardMedia
              src={image}
              onLoad={() => setIsLoaded(true)}
              component="img"
              sx={{
                width: isLoaded && !showDefaultImage ? "100%" : "0",
                height: isLoaded && !showDefaultImage ? "240px" : "0",
                margin: 0,
                boxShadow: ({ boxShadows: { md } }) => md,
                objectFit: "cover",
                objectPosition: "center",
                opacity: isLoaded && !showDefaultImage ? 1 : 0,
                transition: "opacity 0.6s ease-in-out",
              }}
            />
            {/* <Box
              component="img"
              src={image}
              sx={{
                width: "100%",
                height: "240px",
                backgroundSize: "cover",
                display: "block",
                borderRadius: "4px",
              }}
            /> */}
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box sx={{ marginInlineStart: "1rem" }}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2">{description}</Typography>
            <Typography variant="body2"> التسلسل : {sortId}</Typography>
            <Typography variant="body2"> الفديو : {video}</Typography>
            <Typography variant="body2"> المدة : {duration}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box
            sx={{
              // position: "absolute",
              // bottom: "1rem",
              // right: "1rem ",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginInlineEnd: "4rem",
              gap: "0.4rem",
              width: "100%",
              paddingInlineEnd: "6rem",
            }}
          >
            <Box
              sx={{
                color: "white !important",
                cursor: "pointer",
                borderRadius: "8px",
                width: "2rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background: "rgba(25, 105, 217, 0.98) !important",
                },
              }}
              className="stage-overlay-blue rotate-on-hover"
              onClick={() => editLectureClicked()}
            >
              <Icon fontSize="medium">edit_note</Icon>
            </Box>
            <Box
              sx={{
                color: "white !important",
                cursor: "pointer",
                borderRadius: "8px",
                width: "2rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  background: "rgba(135, 47, 47, 0.98) !important",
                },
              }}
              className="stage-overlay-red rotate-on-hover"
              onClick={() => setOpenDialog(true)}
            >
              <Icon fontSize="medium">delete_forever</Icon>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <YesOrNoDialog
        heading="هل انت متأكد من رغبتك في الحذف !؟"
        description=" لأنه لا يمكنك التراجع او استرجاع اي بيانات فيما بعد هل انت متأكد فعلا لا يمكنك التراجع فيما بعد لذلك ارجوك كن حذرا مع تلك العملية"
        functiontoDo={handleDeleteLectureFromCourse}
        openDialog={openDialog}
        closeFunction={handleClose}
      />
    </Card>
  );
}

export default LectureCard;
