// @mui material components
import { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { useMaterialUIController, setSuccessMSG } from "context";

import { useMutation, useQuery } from "react-query";
import { AddTeacher } from "utils/apis/teachers";
import { GetCourses } from "utils/apis/courses";
import MDButton from "components/MDButton";

import useCourses from "hooks/useCourses";
import IconUploader from "examples/IconUploader";

function TeacherInfo() {
  const [controller, dispatch] = useMaterialUIController();
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const navigate = useNavigate();

  const { mutate, isError, isLoading, error } = useMutation({
    mutationFn: AddTeacher,
    onSuccess: (response) => {
      navigate("/teachers");
      setSuccessMSG(dispatch, "تمت عملية اضافة المدرس بنجاح");
    },
  });

  const handleAdd = () => {
    mutate({ name, bio, file: imageFile });
  };

  return (
    <MDBox>
      <MDTypography variant="h5">المدرس الجديد</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3} flexDirection="column">
          <Grid item xs={12}>
            <MDInput
              type="text"
              label="الأسم"
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <MDInput
              type="text"
              label="البايو"
              variant="outlined"
              sx={{ width: "100%" }}
              rows={2}
              multiline
              onChange={(e) => setBio(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <IconUploader fileChange={setImageFile} />
          </Grid>
          {isError && (
            <MDBox mt={2} mb={1}>
              <MDTypography
                variant="caption"
                display="block"
                color="error"
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {error?.response?.data?.message}
              </MDTypography>
            </MDBox>
          )}

          <Grid item xs={12} container justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => handleAdd()}
              disabled={isLoading}
            >
              مدرس جديد
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default TeacherInfo;
