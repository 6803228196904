// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Otis Admin PRO React components
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

function FormField({ label, errorMsg, ...rest }) {
  return (
    <>
      <MDInput {...rest} label={label} variant="standard" autoComplete="off" fullWidth />
      <MDBox mt={0.75}>
        <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
          {errorMsg}
        </MDTypography>
      </MDBox>
    </>
  );
}

// typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string.isRequired,
};

export default FormField;
