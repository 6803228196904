// @mui material components
import Grid from "@mui/material/Grid";

// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Settings page components
import TabLayout from "examples/TabLayout";
import CourseCard from "examples/Cards/ProjectCards/CourseCard";

import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { GetSubjectsAndCourses } from "utils/apis/home";
import { useEffect, useMemo, useState } from "react";
import useSubjects from "hooks/useSubjects";
import LoadingComponent from "examples/LoadingComponent";
import NotFoundComponent from "components/NotFoundComponent";

function StageDetails() {
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const { subjectsAndCoursesData, isLoading } = useSubjects(id);

  const [targetSubject, setTargetSubject] = useState(null);

  const setActiveTab = (value) => {
    setSearchParams({ subject: value });
  };

  const subjects = useMemo(() => {
    const arrayOfSubjects = subjectsAndCoursesData?.map(
      (subjectAndCourse) => subjectAndCourse?.name
    );

    return arrayOfSubjects;
  }, [subjectsAndCoursesData]);

  useEffect(() => {
    if (subjects?.length > 0 && !searchParams.get("subject")) {
      setSearchParams({ subject: subjects[0] });
    }
  }, [subjects]);

  // const targetSubject = useMemo(() => {
  //   if (subjectsAndCoursesData && searchParams) {
  //     const target = subjectsAndCoursesData?.filter(
  //       (subjectAndCourse) => subjectAndCourse?.name == searchParams.get("subject")
  //     );
  //     if (target?.length) {
  //       const targetASObj = target[0];

  //       return targetASObj;
  //     }
  //     return null;
  //   }
  // }, [subjectsAndCoursesData, searchParams]);

  useEffect(() => {
    if (subjectsAndCoursesData && searchParams) {
      const target = subjectsAndCoursesData?.find(
        (subjectAndCourse) => subjectAndCourse?.name === searchParams.get("subject")
      );
      setTargetSubject(target || null);
    }
  }, [subjectsAndCoursesData, searchParams]);

  const handleDeleteLocal = (targetId) => {
    setTargetSubject((prevSubject) => ({
      ...prevSubject,
      courses: prevSubject?.courses?.filter((course) => course.id !== targetId),
    }));
  };

  const renderTabContent = () => {
    if (isLoading || !targetSubject?.courses) {
      return <LoadingComponent />;
    }

    if (targetSubject?.courses?.length === 0) {
      return <NotFoundComponent />;
    }

    return (
      <Grid container spacing={2}>
        {targetSubject?.courses?.map((course) => (
          <Grid item xs={12} md={6} xl={3} key={course?.id}>
            <CourseCard
              courseId={course?.id}
              onDelete={handleDeleteLocal}
              image={course?.image}
              stage={course?.stage}
              teacherName={course?.teacher_name}
              courseName={course?.name}
              numberOfLectures={course?.number_of_lectures}
              ratingsAverage={course?.average_rating}
              action={{
                type: "internal",
                route: `/course/details/${course?.id}`,
                color: "info",
                label: "تعديل الكورس",
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <TabLayout tabs={subjects} setActiveTab={setActiveTab} activeTab={searchParams.get("subject")}>
      <MDBox mt={3}>{renderTabContent()}</MDBox>
    </TabLayout>
  );
}

export default StageDetails;
