/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewCourse page components
import CourseInfo from "layouts/courses/components/CoursePreview/components/CourseInfo";
import CourseDetails from "layouts/courses/components/CoursePreview/components/CourseDetails";
import Media from "layouts/courses/components/CoursePreview/components/Media";
import Lectures from "layouts/courses/components/CoursePreview/components/Lectures";
import Pricing from "layouts/courses/components/CoursePreview/components/Pricing";

import validations from "layouts/courses/components/CoursePreview/schemas/validations";
import {
  CreateCourse,
  AddLecturesToCourse,
  GetLecturesFromCourse,
  GetCourseById,
  EditCourse,
} from "utils/apis/courses";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController, setSuccessMSG, setErrorMSG } from "context";
import { Box } from "@mui/material";
import useCourseById from "hooks/useCourseById";

function getSteps() {
  return ["1- المعلومات الأساسية", "2. التسعير والتصنيف", "3. التفاصيل", "4. الوسائط"];
}

function getStepContent(stepIndex, state, getLecturesResults) {
  const { courseData, setCourseData, errors } = state;
  switch (stepIndex) {
    case 0:
      return (
        <CourseInfo
          localState={courseData.courseInfo}
          setParentState={setCourseData}
          errors={errors}
        />
      );
    case 1:
      return (
        <Pricing localState={courseData.pricing} setParentState={setCourseData} errors={errors} />
      );
    case 2:
      return (
        <CourseDetails
          localState={courseData.courseDetails}
          setParentState={setCourseData}
          errors={errors}
        />
      );
    case 3:
      return <Media localState={courseData.media} setParentState={setCourseData} errors={errors} />;

    default:
      return null;
  }
}

function CreateNewCourse({ title, description }) {
  const { id } = useParams();
  const { courseDetailsData } = useCourseById(id);

  const [activeStep, setActiveStep] = useState(0);
  const [theCourseIsCreated, setTheCourseIsCreated] = useState(false);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const isDataOfCourseFinish = activeStep === 3;
  const currentValidation = validations[activeStep];
  const navigate = useNavigate();
  const [createdCourseId, setCreatedCourseId] = useState(null);
  const [controller, dispatch] = useMaterialUIController();

  const {
    mutateAsync,
    isError,
    isLoading,
    error: createError,
  } = useMutation({
    mutationFn: ({ courseData, courseId }) => EditCourse(courseData, courseId),
    onSuccess: (response) => {
      // navigate("/home/slides");
      setCreatedCourseId(response?.data?.id);
      setTheCourseIsCreated(true);
      setSuccessMSG(dispatch, "تمت عملية تحديث الكورس بنجاح");
    },
    onError: (response) => {
      setErrorMSG(dispatch, response?.response?.statusText);
    },
  });

  const [courseData, setCourseData] = useState({
    courseInfo: {
      courseName: null,
      description: null,
      teacherId: null,
      stageId: null,
      subjectId: null,
      // just for view
      selectedTeacherObj: null,
      selectedStageObj: null,
      selectedSubjectObj: null,
    },
    pricing: {
      price: null,
      expireDate: null,
      // is_featured the course be in sliders of home page
      is_featured: false,
      // is_recent: false,
      is_popular: false,
    },
    courseDetails: {
      whatWeProvide: Array(2)
        .fill()
        .map(() => ({
          icon: null,
          title: null,
          description: null,
          // just for view
          selectedWhatWeProvideIconObj: null,
        })),
      whatWeTeach: Array(3)
        .fill()
        .map(() => ({ description: null })),
    },
    media: {
      mobileScreenImage: null,
      wideScreenImage: null,
      trailers: [{ title: "نبذة عن الدورة", video: "vimo.mp4" }],
      groupChatLink: null,
    },
  });

  useEffect(() => {
    setCourseData({
      courseInfo: {
        courseName: courseDetailsData?.name,
        description: courseDetailsData?.description,
        teacherId: courseDetailsData?.teacher_id,
        stageId: courseDetailsData?.stage_id,
        subjectId: courseDetailsData?.subject_id,
        // just for view
        selectedTeacherObj: courseDetailsData?.teacher,
        selectedStageObj: courseDetailsData?.stage,
        selectedSubjectObj: courseDetailsData?.subject,
      },
      pricing: {
        price: courseDetailsData?.price,
        expireDate: courseDetailsData?.expire_date,
        // is_featured the course be in sliders of home page
        is_featured: courseDetailsData?.is_featured,
        // is_recent: false,
        is_popular: courseDetailsData?.is_popular,
      },
      courseDetails: {
        whatWeProvide: courseDetailsData?.what_we_provide,
        whatWeTeach: courseDetailsData?.what_we_teach,
      },
      media: {
        mobileScreenImage: courseDetailsData?.mobile_screen_image,
        wideScreenImage: courseDetailsData?.wide_screen_image,
        trailers: courseDetailsData?.trailers,
        groupChatLink: courseDetailsData?.group_chat_link,
      },
    });
  }, [id, courseDetailsData]);

  const [errors, setErrors] = useState({});

  const handleNext = async () => {
    try {
      switch (activeStep) {
        case 0: {
          setErrors({});
          await currentValidation.validate(courseData.courseInfo, { abortEarly: false });
          break; // Prevent fall-through
        }

        case 1: {
          setErrors({});
          await currentValidation.validate(courseData.pricing, { abortEarly: false });
          break; // Prevent fall-through
        }

        case 2: {
          setErrors({});
          await currentValidation.validate(courseData.courseDetails, { abortEarly: false });
          break; // Prevent fall-through
        }

        case 3: {
          setErrors({});
          await currentValidation.validate(courseData.media, { abortEarly: false });
          break; // Prevent fall-through
        }
      }

      setActiveStep(activeStep + 1);
    } catch (error) {
      const newErrors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      }

      setErrors(newErrors);
    }
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const editCourse = async () => {
    try {
      setErrors({});
      await currentValidation.validate(courseData.media, { abortEarly: false });
      const courseDataObject = {
        name: courseData.courseInfo.courseName,
        teacher_id: Number(courseData.courseInfo.teacherId),
        stage_id: courseData.courseInfo.stageId,
        subject_id: courseData.courseInfo.subjectId,
        description: courseData.courseInfo.description,
        price: Number(courseData.pricing.price),
        expire_date: courseData.pricing.expireDate,
        is_featured: courseData.pricing.is_featured,
        is_popular: courseData.pricing.is_popular,
        what_we_provide: courseData.courseDetails.whatWeProvide,
        what_we_teach: courseData.courseDetails.whatWeTeach,
        trailers: courseData.media.trailers,
        mobile_screen_image: courseData.media.mobileScreenImage,
        wide_screen_image: courseData.media.wideScreenImage,
        group_chat_link: courseData.media.groupChatLink,
      };

      return await mutateAsync({ courseData: courseDataObject, courseId: id });
    } catch (error) {
      const newErrors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      }

      setErrors(newErrors);
    }
  };

  return (
    <MDBox mt={1}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8}>
          <MDBox mb={6} textAlign="center">
            <MDBox mb={1}>
              <MDTypography variant="h3" fontWeight="bold">
                {title}
              </MDTypography>
            </MDBox>
            <MDTypography variant="h5" fontWeight="regular" color="secondary">
              {description}
            </MDTypography>
          </MDBox>
          <Card>
            <MDBox mt={-3} mb={3} mx={2}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MDBox>

            <MDBox p={2}>
              <MDBox>
                {getStepContent(activeStep, { courseData, setCourseData, errors })}
                <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                  {activeStep === 0 ? (
                    <MDBox />
                  ) : (
                    <MDButton variant="gradient" color="light" onClick={handleBack}>
                      رجوع
                    </MDButton>
                  )}
                  <Box sx={{ display: "flex", gap: 2 }}>
                    {isLastStep && (
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={editCourse}
                        disabled={theCourseIsCreated || isLoading}
                      >
                        تعديل الكورس
                      </MDButton>
                    )}

                    {!isLastStep && (
                      <MDButton variant="gradient" color="dark" onClick={handleNext}>
                        التالي
                      </MDButton>
                    )}
                  </Box>
                </MDBox>
              </MDBox>

              {isError && (
                <MDBox mt={2} mb={1}>
                  <MDTypography
                    variant="caption"
                    display="block"
                    color="error"
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    {createError?.response?.data?.message}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default CreateNewCourse;
