import { useEffect, useMemo, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/courses/components/CoursePreview/components/FormField";

import useTeachers from "hooks/useTeachers";
import useStages from "hooks/useStages";
import useSubjects from "hooks/useSubjects";

function CourseInfo({ localState, setParentState, errors }) {
  const { teachers: AllTeachersForSelect } = useTeachers();
  const { stagesData } = useStages();
  const [selectedStageId, setSelectedStageId] = useState(localState.stageId || null);
  const { subjectsAndCoursesData } = useSubjects(selectedStageId);

  // just for edit
  useEffect(() => {
    setSelectedStageId(localState.stageId || null);
  }, [localState.stageId]);

  const subjects = useMemo(() => {
    const arrayOfSubjects = subjectsAndCoursesData?.map((subjectAndCourse) => {
      return { name: subjectAndCourse?.name, id: subjectAndCourse?.id };
    });

    return arrayOfSubjects;
  }, [subjectsAndCoursesData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setParentState((prevState) => ({
      ...prevState,
      courseInfo: {
        ...prevState.courseInfo,
        [name]: value,
      },
    }));
  };

  const teacherChange = (event, value) => {
    setParentState((prevState) => ({
      ...prevState,
      courseInfo: {
        ...prevState.courseInfo,
        teacherId: value?.id || null,
        selectedTeacherObj: value,
      },
    }));
  };

  const stageChange = (event, value) => {
    setSelectedStageId(value?.id || null);
    setParentState((prevState) => ({
      ...prevState,
      courseInfo: {
        ...prevState.courseInfo,
        stageId: value?.id || null,
        selectedStageObj: value,
      },
    }));
  };

  const subjectChange = (event, value) => {
    setParentState((prevState) => ({
      ...prevState,
      courseInfo: {
        ...prevState.courseInfo,
        subjectId: value?.id || null,
        selectedSubjectObj: value,
      },
    }));
  };

  return (
    <MDBox>
      <MDTypography variant="h5">المعلومات الأساسية</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="اسم الكورس"
              name="courseName"
              onChange={handleChange}
              value={localState.courseName}
              error={errors?.courseName}
              errorMsg={errors?.courseName}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={localState.selectedTeacherObj || null}
              options={AllTeachersForSelect || []}
              getOptionLabel={(option) => option.name}
              onChange={teacherChange}
              renderInput={(params) => (
                <MDInput {...params} label="مدرس الكورس" variant="standard" />
              )}
            />
            {errors?.teacherId && (
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {errors?.teacherId}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox mb={1} ml={0.5}>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                الوصف&nbsp;&nbsp;
              </MDTypography>
            </MDBox>
            <MDInput
              style={{ width: "100%" }}
              multiline
              rows={5}
              name="description"
              onChange={handleChange}
              value={localState.description}
              error={errors?.description}
            />
            {errors?.description && (
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {errors?.description}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <MDBox mb={3}>
              <Autocomplete
                value={localState.selectedStageObj || null}
                options={stagesData || []}
                getOptionLabel={(option) => option.name}
                onChange={stageChange}
                renderInput={(params) => (
                  <MDInput {...params} label="المرحلة الدراسية" variant="standard" />
                )}
              />
              {errors?.stageId && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors?.stageId}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
            <MDBox mb={3}>
              <Autocomplete
                value={localState.selectedSubjectObj || null}
                options={subjects || []}
                getOptionLabel={(option) => option.name}
                onChange={subjectChange}
                renderInput={(params) => (
                  <MDInput {...params} label="المادة الدراسية" variant="standard" />
                )}
              />
              {errors?.subjectId && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors?.subjectId}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default CourseInfo;
