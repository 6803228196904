// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import MDDatePicker from "components/MDDatePicker";

// NewProduct page components

import { Box, Stack } from "@mui/material";
import { formatDateToISO } from "utils/methods";

function Pricing({ localState, setParentState, errors }) {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setParentState((prevState) => ({
      ...prevState,
      pricing: {
        ...prevState.pricing,
        [name]: value,
      },
    }));
  };

  const handleExpireDateChange = (_, value) => {
    setParentState((previouseState) => {
      return {
        ...previouseState,
        pricing: {
          ...previouseState.pricing,
          expireDate: value ? formatDateToISO(value) : null,
        },
      };
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setParentState((prevState) => ({
      ...prevState,
      pricing: {
        ...prevState.pricing,
        [name]: checked,
      },
    }));
  };

  return (
    <MDBox>
      <MDTypography variant="h5">التسعير</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MDInput
                type="number"
                autoComplete="off"
                label="السعر"
                placeholder="99.00"
                variant="standard"
                name="price"
                onChange={handleChange}
                value={localState.price}
                error={errors?.price}
              />
              {errors?.price && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors?.price}
                  </MDTypography>
                </MDBox>
              )}

              <MDBox sx={{ mt: 2 }}>
                <MDDatePicker
                  input={{
                    id: "expire_date",
                    name: "expire_date",
                    placeholder: "الصلاحية لغاية ؟",
                    variant: "standard",
                    fullWidth: true,
                    error: errors?.expireDate,
                  }}
                  defaultValue={localState.expireDate}
                  onChange={handleExpireDateChange}
                  name="expire_date"
                  // options={{
                  //   enableTime: true,
                  //   enableSeconds: true,
                  // }}
                />
                {errors?.expireDate && (
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      {errors?.expireDate}
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_featured"
                        onChange={handleCheckboxChange}
                        checked={localState.is_featured}
                      />
                    }
                    label="اضافة الى السلايدات"
                  />
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        name="is_recent"
                        onChange={handleCheckboxChange}
                        checked={localState.is_recent}
                      />
                    }
                    label="is_recent"
                  /> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_popular"
                        onChange={handleCheckboxChange}
                        checked={localState.is_popular}
                      />
                    }
                    label="اضافة الى الكورسات المميزة"
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default Pricing;
