import { useMemo, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";

import IconUploader from "examples/IconUploader";
import MDButton from "components/MDButton";
import { Box, Icon, Stack } from "@mui/material";
import useIcons from "hooks/useIcons";

function CourseDetails({ localState, setParentState, errors }) {
  const { iconsData } = useIcons();

  const whatWeProvideIconChange = (event, value, index) => {
    setParentState((prevState) => ({
      ...prevState,
      courseDetails: {
        ...prevState.courseDetails,
        whatWeProvide: [
          ...prevState.courseDetails.whatWeProvide.slice(0, index),
          {
            ...prevState.courseDetails.whatWeProvide[index],
            icon: value?.Icon,
            selectedWhatWeProvideIconObj: value,
          },
          ...prevState.courseDetails.whatWeProvide.slice(index + 1),
        ],
      },
    }));
  };

  const handleChangeWhatWeProvide = (event, index) => {
    const { name, value } = event.target;
    setParentState((prevState) => ({
      ...prevState,
      courseDetails: {
        ...prevState.courseDetails,
        whatWeProvide: [
          ...prevState.courseDetails.whatWeProvide.slice(0, index),
          { ...prevState.courseDetails.whatWeProvide[index], [name]: value },
          ...prevState.courseDetails.whatWeProvide.slice(index + 1),
        ],
      },
    }));
  };

  const handleChangewhatWeTeach = (event, index) => {
    const { name, value } = event.target;
    setParentState((prevState) => ({
      ...prevState,
      courseDetails: {
        ...prevState.courseDetails,
        whatWeTeach: [
          ...prevState.courseDetails.whatWeTeach.slice(0, index),
          { ...prevState.courseDetails.whatWeTeach[index], [name]: value },
          ...prevState.courseDetails.whatWeTeach.slice(index + 1),
        ],
      },
    }));
  };

  const addNewwhatWeTeachItem = () => {
    setParentState((prevState) => ({
      ...prevState,
      courseDetails: {
        ...prevState.courseDetails,
        whatWeTeach: [...prevState.courseDetails.whatWeTeach, { description: null }],
      },
    }));
  };

  const deleteWhatWeTeachItem = (index) => {
    setParentState((prevState) => ({
      ...prevState,
      courseDetails: {
        ...prevState.courseDetails,
        whatWeTeach: [
          ...prevState.courseDetails.whatWeTeach.slice(0, index),
          ...prevState.courseDetails.whatWeTeach.slice(index + 1),
        ],
      },
    }));
  };

  const addNewWhatWeProvide = () => {
    setParentState((prevState) => ({
      ...prevState,
      courseDetails: {
        ...prevState.courseDetails,
        whatWeProvide: [
          ...prevState.courseDetails.whatWeProvide,
          { title: null, description: null, icon: null, selectedWhatWeProvideIconObj: null },
        ],
      },
    }));
  };
  const deleteWhatWeProvide = (index) => {
    setParentState((prevState) => ({
      ...prevState,
      courseDetails: {
        ...prevState.courseDetails,
        whatWeProvide: [
          ...prevState.courseDetails.whatWeProvide.slice(0, index),
          ...prevState.courseDetails.whatWeProvide.slice(index + 1),
        ],
      },
    }));
  };
  return (
    <MDBox>
      <MDTypography variant="h5">التفاصيل</MDTypography>
      <MDBox mt={3} mb={5}>
        <MDTypography variant="h6" mt={0}>
          ماذا نقدم ؟
        </MDTypography>

        {localState?.whatWeProvide?.map((singleThing, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item sm={3}>
              <MDBox sx={{ marginBottom: 0.8 }}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  الأيقونه
                </MDTypography>
              </MDBox>
              <Autocomplete
                value={localState.whatWeProvide?.[index]?.selectedWhatWeProvideIconObj || null}
                options={iconsData || []}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => whatWeProvideIconChange(event, value, index)}
                renderInput={(params) => <MDInput {...params} label="الأيقونه" />}
              />
            </Grid>
            <Grid item xs={10} sm={4}>
              <MDBox mb={1} ml={0.5}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  العنوان&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDInput
                style={{ width: "100%" }}
                name="title"
                multiline
                rows={1.3}
                onChange={(event) => handleChangeWhatWeProvide(event, index)}
                value={localState.whatWeProvide?.[index]?.title}
                error={errors?.[`whatWeProvide[${index}].title`]}
              />

              {errors?.[`whatWeProvide[${index}].title`] && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors?.[`whatWeProvide[${index}].title`]}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={5}>
              <MDBox mb={1} ml={0.5}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  الوصف&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <MDInput
                style={{ width: "100%" }}
                name="description"
                multiline
                rows={1.3}
                onChange={(event) => handleChangeWhatWeProvide(event, index)}
                value={localState.whatWeProvide?.[index]?.description}
                error={errors?.[`whatWeProvide[${index}].description`]}
              />
              {errors?.[`whatWeProvide[${index}].description`] && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors?.[`whatWeProvide[${index}].description`]}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
            {index > 1 && (
              <Grid item xl={2}>
                <MDButton variant="text" color="error" onClick={() => deleteWhatWeProvide(index)}>
                  <Icon>delete</Icon>&nbsp;حذف
                </MDButton>
              </Grid>
            )}
          </Grid>
        ))}
        <MDBox mt={3}>
          <MDButton variant="gradient" color="info" onClick={addNewWhatWeProvide}>
            نقطة اخرى
          </MDButton>
        </MDBox>
        {errors?.whatWeProvide && (
          <MDBox mt={1}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              {errors?.whatWeProvide}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
      <MDBox mt={3} mb={5}>
        <MDTypography variant="h6" mt={0}>
          ماذا سوف تتعلم ؟
        </MDTypography>

        {localState?.whatWeTeach?.map((singleThing, index) => (
          <Grid container spacing={2} alignItems="center" mt={0.5}>
            <Grid item xs={12} sm={6}>
              <MDBox mb={1} ml={0.5}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  الوصف&nbsp;&nbsp;
                </MDTypography>
              </MDBox>
              <Box sx={{ display: "flex", gap: 2 }}>
                <MDInput
                  style={{ width: "100%" }}
                  name="description"
                  onChange={(event) => handleChangewhatWeTeach(event, index)}
                  value={localState.whatWeTeach?.[index]?.description}
                  error={errors?.[`whatWeTeach[${index}].description`]}
                />
                {index > 2 && (
                  <MDButton
                    variant="text"
                    color="error"
                    onClick={() => deleteWhatWeTeachItem(index)}
                  >
                    <Icon>delete</Icon>&nbsp;حذف
                  </MDButton>
                )}
              </Box>

              {errors?.[`whatWeTeach[${index}].description`] && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors?.[`whatWeTeach[${index}].description`]}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
          </Grid>
        ))}

        <MDBox mt={3}>
          <MDButton variant="gradient" color="info" onClick={addNewwhatWeTeachItem}>
            نقطة اخرى
          </MDButton>
        </MDBox>
        {errors?.whatWeTeach && (
          <MDBox mt={1}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              {errors?.whatWeTeach}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

export default CourseDetails;
