import { useMemo } from "react";
import { useQuery } from "react-query";
import { GetCourses } from "utils/apis/courses";

const useCourses = () => {
  const { data: coursesData, isLoading, isError, error } = useQuery(["courses"], GetCourses);

  const courses = useMemo(() => {
    if (coursesData?.data) {
      return coursesData.data.map((course) => ({
        id: course.id,
        name: course.name,
      }));
    }
    return []; // Return an empty array if no data is available
  }, [coursesData]);

  return { courses, isLoading, isError, error };
};

export default useCourses;
