// Otis Admin PRO React components
import MDBox from "components/MDBox";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import useStages from "hooks/useStages";
import StagesComponent from "./components/StagesComponent";

function StagesAndSubjects() {
  const { stagesData } = useStages();

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {stagesData && (
        <MDBox mt={3}>
          <StagesComponent stages={stagesData} />
        </MDBox>
      )}

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default StagesAndSubjects;
