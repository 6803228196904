import { useMemo } from "react";
import { useQuery } from "react-query";
import { GetStages } from "utils/apis/home";

const useStages = () => {
  const { data, isLoading, isError, error } = useQuery(["stages"], GetStages);

  return { stagesData: data?.data, isLoading, isError, error };
};

export default useStages;
