import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Tooltip from "@mui/material/Tooltip";

import imageCompression from "browser-image-compression";

import defaultImageUploader from "assets/images/icon-upload.png";
import MDBox from "components/MDBox";

const Input = styled("input")({
  display: "none",
});

const ImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%", // Adjust size as needed
  height: "auto", // Adjust size as needed
  "&:hover > div": {
    opacity: 1,
  },
}));

const Overlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: "rgba(255, 255, 255, 0.5)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  transition: "opacity 0.25s ease",
});

function IconUploader({ defaultImage = defaultImageUploader, fileChange }) {
  const [image, setImage] = useState(defaultImage);
  const fileInputRef = useRef(null);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    try {
      if (file) {
        let compressedFile = file;

        if (file.size > 1048576) {
          compressedFile = await imageCompression(file, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
          });
        }
        const reader = new FileReader();
        reader.onload = function (e) {
          setImage(e.target.result);
        };
        reader.readAsDataURL(compressedFile);
        // Check if iconFileChange is a function and call it with the file
        if (typeof fileChange === "function") {
          fileChange(compressedFile);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = () => {
    // Trigger the hidden input's click event
    fileInputRef.current.click();
  };

  return (
    <ImageContainer onClick={handleClick}>
      <img
        src={image}
        alt="Upload Icon"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
      <Overlay>
        <label htmlFor="icon-button-file" onClick={(e) => e.stopPropagation()}>
          <Input
            id="icon-button-file"
            type="file"
            ref={fileInputRef}
            onChange={handleImageChange}
            accept="image/jpg, image/jpeg"
          />
          <Tooltip title="رفع صورة" arrow>
            <MDBox component="span" sx={{ fontSize: "small", fontWeight: "bold" }}>
              تغيير
            </MDBox>
          </Tooltip>
        </label>
      </Overlay>
    </ImageContainer>
  );
}

export default IconUploader;
