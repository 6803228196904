import { Box, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { GetLecturesFromCourse } from "utils/apis/courses";
import LectureCard from "./LectureCard";
import LoadingComponent from "examples/LoadingComponent";
import NotFoundComponent from "components/NotFoundComponent";
import MDButton from "components/MDButton";
import LectureDialog from "./LectureDialog";

export default function LecturesView() {
  const [lectures, setLectures] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [activeLecture, setActiveLecture] = useState();
  const [ActiveTypeOfModal, setActiveTypeOfModal] = useState("new");
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActiveLecture(null);
  };
  const handleClickOpenDialog = () => {
    setActiveTypeOfModal("new");
    setActiveLecture(null);
    setOpenDialog(true);
  };
  const editLectureClicked = (lecture) => {
    setActiveTypeOfModal("edit");

    setActiveLecture(lecture);
    setOpenDialog(true);
  };

  const { id } = useParams();

  const handleDeleteLecture = (deletedLectureId) => {
    // setSlides((prevSlides) => prevSlides.filter((slide) => slide.id !== id));
    setLectures((prev) => prev.filter((lecture) => lecture.id != deletedLectureId));
  };

  const {
    data: allLectures,
    error,
    isLoading,
    refetch,
  } = useQuery(["lectures", id], () => GetLecturesFromCourse(id), {
    // staleTime: 5 * 60 * 1000,
    // cacheTime: 10 * 60 * 1000,
    // refetchOnWindowFocus: false,
    onSuccess: (data) => setLectures(data?.data || []),
  });

  // Sort the slides based on the sort_number property
  const sortedLectures = useMemo(() => {
    return lectures?.sort((a, b) => a.sort_number - b.sort_number);
  }, [lectures]);

  const renderLecturesContent = () => {
    if (isLoading || !allLectures?.data) {
      return <LoadingComponent />;
    }

    if (allLectures?.data?.length === 0) {
      return <NotFoundComponent />;
    }
    // const sortedLectures = allLectures.data.sort((a, b) => a.sort_id - b.sort_id);

    return (
      <Box>
        {sortedLectures?.length > 0 && (
          <MDBox mt={1}>
            {sortedLectures.map((lecture) => (
              <MDBox mt={2} key={lecture?.id}>
                <LectureCard
                  id={lecture?.id}
                  courseId={id}
                  title={lecture?.title}
                  description={lecture?.description}
                  image={lecture?.image}
                  sortId={lecture?.sort_id}
                  duration={lecture?.duration}
                  video={lecture?.video}
                  editLectureFunction={() => editLectureClicked(lecture)}
                  onDeleteLecture={(deletedId) => handleDeleteLecture(deletedId)}
                />
              </MDBox>
            ))}
          </MDBox>
        )}
      </Box>
    );
  };
  return (
    <div>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
        {/* <Link to="/home/slides/create"> */}
        <MDButton variant="gradient" color="info" onClick={handleClickOpenDialog}>
          محاظرة جديدة
        </MDButton>
        {/* </Link> */}
      </MDBox>
      {renderLecturesContent()}

      <LectureDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        courseId={id}
        lecture={activeLecture}
        type={ActiveTypeOfModal}
        refetch={refetch}
      />
    </div>
  );
}
