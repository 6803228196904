import { useMemo } from "react";

import FormField from "layouts/courses/components/CoursePreview/components/FormField";
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Dropzone from "components/Dropzone";
import { Box, Grid, Icon } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

function Media({ localState, setParentState, errors }) {
  console.log(localState);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setParentState((prevState) => ({
      ...prevState,
      media: {
        ...prevState.media,
        [name]: value,
      },
    }));
  };

  const handleChangeTrailers = (event, index) => {
    const { name, value } = event.target;
    setParentState((prevState) => ({
      ...prevState,
      media: {
        ...prevState.media,
        trailers: [
          ...prevState.media.trailers.slice(0, index),
          { ...prevState.media.trailers[index], [name]: value },
          ...prevState.media.trailers.slice(index + 1),
        ],
      },
    }));
  };
  const addNewTrailers = () => {
    setParentState((prevState) => ({
      ...prevState,
      media: {
        ...prevState.media,
        trailers: [...prevState.media.trailers, { title: null, video: null }],
      },
    }));
  };

  const deleteTrailer = (index) => {
    setParentState((prevState) => ({
      ...prevState,
      media: {
        ...prevState.media,
        trailers: [
          ...prevState.media.trailers.slice(0, index),
          ...prevState.media.trailers.slice(index + 1),
        ],
      },
    }));
  };

  const initWideScreenImage = localState.wideScreenImage ? [localState.wideScreenImage] : null;

  const handleWideScreenImageChange = (files) => {
    if (files?.length > 0)
      setParentState((prevState) => ({
        ...prevState,
        media: {
          ...prevState.media,
          wideScreenImage: files?.[0],
        },
      }));
  };

  const initMobileScreenImage = localState.mobileScreenImage
    ? [localState.mobileScreenImage]
    : null;

  const handleMobileScreenImageChange = (files) => {
    if (files?.length > 0)
      setParentState((prevState) => ({
        ...prevState,
        media: {
          ...prevState.media,
          mobileScreenImage: files?.[0],
        },
      }));
  };

  return (
    <MDBox>
      <MDTypography variant="h5">الوسائط</MDTypography>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            صورة الغلاف للأجهزة الكبيرة
          </MDTypography>
        </MDBox>
        {useMemo(
          () => (
            <Dropzone
              onFilesChange={handleWideScreenImageChange}
              initFiles={initWideScreenImage || null}
            />
          ),
          []
        )}
      </MDBox>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            صورة الغلاف للهواتف
          </MDTypography>
        </MDBox>
        {useMemo(
          () => (
            <Dropzone
              onFilesChange={handleMobileScreenImageChange}
              initFiles={initMobileScreenImage || null}
            />
          ),
          []
        )}
      </MDBox>
      <MDBox mt={3}>
        <FormField
          type="text"
          label="رابط كروب الواتساب"
          name="groupChatLink"
          onChange={handleChange}
          value={localState.groupChatLink}
          error={errors?.groupChatLink}
          errorMsg={errors?.groupChatLink}
        />
      </MDBox>
      <MDBox mt={3} mb={5}>
        <MDTypography variant="h6" mt={0}>
          الفديوات التعريفية
        </MDTypography>
        {localState?.trailers?.map((trailer, index) => (
          <Grid container spacing={2}>
            <Grid item xs={10} sm={4}>
              <MDBox mb={1} ml={0.5}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  العنوان&nbsp;&nbsp;
                </MDTypography>
              </MDBox>

              <MDInput
                style={{ width: "100%" }}
                multiline
                name="title"
                onChange={(event) => handleChangeTrailers(event, index)}
                value={localState.trailers?.[index]?.title}
                error={errors?.[`trailers[${index}].title`]}
              />
              {errors?.[`trailers[${index}].title`] && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors?.[`trailers[${index}].title`]}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox mb={1} ml={0.5}>
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  تعريف الفديو
                </MDTypography>
              </MDBox>
              <Box sx={{ display: "flex", gap: 2 }}>
                <MDInput
                  style={{ width: "100%" }}
                  multiline
                  name="video"
                  onChange={(event) => handleChangeTrailers(event, index)}
                  value={localState.trailers?.[index]?.video}
                  error={errors?.[`trailers[${index}].video`]}
                />
                {index > 0 && (
                  <MDButton variant="text" color="error" onClick={() => deleteTrailer(index)}>
                    <Icon>delete</Icon>&nbsp;حذف
                  </MDButton>
                )}
              </Box>
              {errors?.[`trailers[${index}].video`] && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors?.[`trailers[${index}].video`]}
                  </MDTypography>
                </MDBox>
              )}
            </Grid>
          </Grid>
        ))}
        <MDBox mt={3}>
          <MDButton variant="gradient" color="info" onClick={addNewTrailers}>
            فديو اخر
          </MDButton>
        </MDBox>
        {errors?.trailers && (
          <MDBox mt={1}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              {errors?.trailers}
            </MDTypography>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
}

export default Media;
