export const formatDateToISO = (dateString) => {
  const dt = new Date(dateString);
  const isoFormat = dt.toISOString();
  return isoFormat;
};

export async function createFile(url) {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type: "image/jpeg",
  };
  const file = new File([data], "test.jpg", metadata);
  return file;
  // ... do something with the file or return it
}
