import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import LoadingLottie from "assets/images/loading.json";
import { Box } from "@mui/material";
function LoadingComponent() {
  const [loadingLottieSize, setLoadingLottieSize] = useState(400);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setLoadingLottieSize(200); // Set the loadingLottieSize for small screens
      } else if (window.innerWidth < 1000) {
        setLoadingLottieSize(300);
      } else {
        setLoadingLottieSize(400); // Set the loadingLottieSize for larger screens
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial call to set the initial slidesPerView value
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box mt={6}>
      <Lottie options={defaultOptions} height={loadingLottieSize} width={loadingLottieSize} />
    </Box>
  );
}

export default LoadingComponent;
