import MDTypography from "components/MDTypography";
import React from "react";
import NoDataImage from "assets/images/no-data.svg";
import { Box } from "@mui/material";
import MDBox from "components/MDBox";

export default function NotFoundComponent() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: 2,
      }}
    >
      <MDBox
        component="img"
        src={NoDataImage}
        alt="no data"
        sx={{
          width: "400px",
          height: "auto",
          objectFit: "cover",
          borderRadius: "6px",
        }}
      />
      <MDTypography
        color="info"
        sx={{
          fontWeight: "bold",
          fontSize: "x-large",
          mt: "-2rem !important",
          pb: "1rem !important",
          textAlign: "center",
        }}
      >
        مع الأسف لا يوجد محتوى
      </MDTypography>
    </Box>
  );
}
