import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { Autocomplete, Box, Dialog, Grid, Icon, IconButton, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import CourseStack from "examples/CourseStack";
import YesOrNoDialog from "examples/YesOrNoDialog";
import { DeleteSlide, EditSlide } from "utils/apis/home";
import { useMutation, useQueryClient } from "react-query";
import { useMaterialUIController, setSuccessMSG, setErrorMSG } from "context";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

import useCourses from "hooks/useCourses";
import useStages from "hooks/useStages";

export default function Slide({ slide, onDelete }) {
  const [controller, dispatch] = useMaterialUIController();
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedStageId, setSelectedStageId] = useState(null);
  const [sortNumber, setSortNumber] = useState(slide?.sort_number);
  const { courses: AllCoursesForSelect } = useCourses();
  const [courseId, setCourseId] = useState(slide?.course_id);

  const { stagesData } = useStages();
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);

  useEffect(() => {
    const target = stagesData.find((element) => element?.name == slide?.stage);
    setSelectedStage(target);
    setSelectedStageId(target?.id);
  }, [slide?.stage, stagesData]);

  useEffect(() => {
    const target = AllCoursesForSelect.find((element) => element?.name == slide?.name);
    setSelectedSubject(target);
  }, [slide?.name, AllCoursesForSelect]);

  const stageChange = (event, value) => {
    setSelectedStageId(value?.id || null);
  };

  const queryClient = useQueryClient();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseEdit = () => {
    setOpenEditDialog(false);
  };

  const { mutate, isError, isLoading, error } = useMutation({
    mutationFn: DeleteSlide,
    onSuccess: (response) => {
      setSuccessMSG(dispatch, "تمت عملية الحذف بنجاح");
      handleClose();
      onDelete(slide?.id);
      // queryClient.invalidateQueries({ queryKey: ["slides"] });
    },
    onError: (err) => {
      setErrorMSG(dispatch, "حدث خطأ أثناء عملية الحذف");
    },
  });
  const handleDeleteSlide = () => {
    mutate(slide?.id);
  };

  const {
    mutate: mutateForEdit,
    isError: isErrorForEdit,
    isLoading: isLoadingForEdit,
    error: errorForEdit,
  } = useMutation({
    mutationFn: ({ SlideData, id }) => EditSlide(SlideData, id),
    onSuccess: (response) => {
      setSuccessMSG(dispatch, "تمت عملية  التعديل بنجاح");
      handleCloseEdit();
      queryClient.invalidateQueries({ queryKey: ["slides"] });
    },
    onError: (err) => {
      setErrorMSG(dispatch, "حدث خطأ أثناء عملية التعديل");
    },
  });

  const handleEdit = () => {
    const SlideData = {
      course_id: courseId,
      sort_number: Number(sortNumber),
      stage_id: selectedStageId,
    };
    mutateForEdit({ SlideData, id: slide?.id });
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          height: "40vh",
          mb: 2,
          flexShrink: "0",
          background: `url(${slide?.wide_screen_image}) rgba(34, 43, 69, 0.78)`,
          position: "relative",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundOrigin: "border-box",
          boxShadow: 5,
        }}
        className="overlay target-hover"
      >
        <Box
          sx={{
            position: "absolute",
            top: "1rem",
            left: "1rem ",
            color: "white !important",
            width: "2rem",
            height: "2rem",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="stage-overlay-black"
        >
          {slide?.sort_number}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "1rem",
            right: "1rem ",
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
          }}
        >
          <Box
            sx={{
              color: "white !important",
              cursor: "pointer",
              borderRadius: "8px",
              width: "2rem",
              height: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                background: "rgba(25, 105, 217, 0.98) !important",
              },
            }}
            onClick={() => setOpenEditDialog(true)}
            className="stage-overlay-blue rotate-on-hover"
          >
            <Icon fontSize="medium">edit_note</Icon>
          </Box>
          <Box
            sx={{
              color: "white !important",
              cursor: "pointer",
              borderRadius: "8px",
              width: "2rem",
              height: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "&:hover": {
                background: "rgba(135, 47, 47, 0.98) !important",
              },
            }}
            onClick={() => setOpenDialog(true)}
            className="stage-overlay-red rotate-on-hover"
          >
            <Icon fontSize="medium">delete_forever</Icon>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography variant="h4" sx={{ color: "white !important", mb: 2 }}>
            {slide?.name}
          </Typography>
          <CourseStack
            teacherName={slide?.teacher_name}
            numberOfLectures={slide?.number_of_lectures}
            ratingsAverage={slide?.ratings_average}
          />
        </Box>
      </Card>
      <YesOrNoDialog
        heading="هل انت متأكد من رغبتك في الحذف !؟"
        description=" لأنه لا يمكنك التراجع او استرجاع اي بيانات فيما بعد هل انت متأكد فعلا لا يمكنك التراجع فيما بعد لذلك ارجوك كن حذرا مع تلك العملية"
        functiontoDo={handleDeleteSlide}
        openDialog={openDialog}
        closeFunction={handleClose}
      />
      <Dialog
        open={openEditDialog}
        onClose={handleCloseEdit}
        aria-labelledby="alert-edit-dialog-title"
        aria-describedby="alert-edit-dialog-description"
        PaperProps={{
          sx: {
            padding: 1.8,
          },
        }}
      >
        <DialogTitle id="alert-edit-dialog-title">تعديل السلايد</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-edit-dialog-description">
            سوف تتمكن من تعديل كافة تفاصيل السلايد
          </DialogContentText>
          <MDBox mt={3} sx={{ minWidth: "480px" }}>
            <Grid container spacing={3} flexDirection="column">
              <Grid item xs={12}>
                <Autocomplete
                  value={selectedSubject || null}
                  options={AllCoursesForSelect}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => setCourseId(value.id)}
                  renderInput={(params) => (
                    <MDInput {...params} label="الدورة التعليمية" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={selectedStage || null}
                  options={stagesData || []}
                  getOptionLabel={(option) => option.name}
                  onChange={stageChange}
                  renderInput={(params) => (
                    <MDInput {...params} label="المرحلة الدراسية" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  type="number"
                  label="التسلسل"
                  variant="outlined"
                  sx={{ width: "100%" }}
                  value={sortNumber}
                  onChange={(e) => setSortNumber(e.target.value)}
                />
              </Grid>
              {isError && (
                <Grid item xs={12}>
                  <MDTypography
                    variant="caption"
                    display="block"
                    color="error"
                    sx={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    {error?.response?.data?.message}
                  </MDTypography>
                </Grid>
              )}
            </Grid>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => handleEdit()}
            disabled={isErrorForEdit}
          >
            تعديل
          </MDButton>
          <MDButton variant="gradient" color="secondary" onClick={handleCloseEdit}>
            الغاء
          </MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
