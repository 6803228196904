// Sales dashboard components
import TeacherCell from "layouts/users/Teachers/components/TeacherCell";
import RefundsCell from "layouts/users/Teachers/components/RefundsCell";
import DefaultCell from "layouts/users/Teachers/components/DefaultCell";
import ActionsCell from "../components/ActionsCell";

export default {
  columns: [
    {
      Header: "اسم المدرس",
      accessor: "teacherDetails",
      width: "55%",
      Cell: ({ value }) => <TeacherCell image={value?.image} name={value?.name} bio={value?.bio} />,
    },
    {
      Header: "عدد المبيعات ",
      accessor: "salesNumber",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "عدد الكورسات الكلي",
      accessor: "numberOfCourses",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "تاريخ الأنظمام",
      accessor: "joinDate",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    {
      Header: "الأجراءات",
      accessor: "actions",
      align: "center",
      Cell: ({ value }) => <ActionsCell value={value} />,
    },

    // {
    //   Header: "refunds",
    //   accessor: "refunds",
    //   align: "center",
    //   Cell: ({ value }) => (
    //     <RefundsCell value={13} icon={{ color: "success", name: "keyboard_arrow_up" }} />
    //   ),
    // },
  ],
};
