import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "context/UserProvider";

function useLogout() {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("user");
    setUser(null);
    navigate("/authentication/sign-in");
  };

  return logout;
}

export default useLogout;
