import { Box, Stack } from "@mui/material";
import Icon from "@mui/material/Icon";

export default function CourseStack({
  teacherName,
  numberOfLectures,
  ratingsAverage,
  color,
  fontSize,
}) {
  const defaultColor = color || "white";
  const defaultFontSize = fontSize || "large";

  return (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      sx={{ color: `${defaultColor} !important`, fontSize: defaultFontSize }}
    >
      {teacherName && <span>{teacherName}</span>}

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 0.5 }}>
        {numberOfLectures} <Icon fontSize="medium">book_5</Icon> عدد الدروس
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 0.5 }}>
        {ratingsAverage}{" "}
        <Icon fontSize="medium" className="color-orange">
          star
        </Icon>
      </Box>
    </Stack>
  );
}
