// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import CourseStack from "examples/CourseStack";
import logo from "assets/images/logo.png";
import { useEffect, useState } from "react";
import YesOrNoDialog from "examples/YesOrNoDialog";
import { DeleteCourse } from "utils/apis/courses";

import { useMaterialUIController, setSuccessMSG } from "context";

import { useMutation, useQueryClient } from "react-query";

function CourseCard({
  image,
  teacherName,
  courseName,
  numberOfLectures,
  ratingsAverage,
  action,
  courseId,
  onDelete,
  stage,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const [isLoaded, setIsLoaded] = useState(false);

  const [showDefaultImage, setShowDefaultImage] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowDefaultImage(false), 600);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const { mutate, isError, isLoading, error } = useMutation({
    mutationFn: DeleteCourse,
    onSuccess: (response) => {
      setSuccessMSG(dispatch, "تمت عملية الحذف بنجاح");
      handleClose();
      onDelete(courseId);
      // queryClient.invalidateQueries({ queryKey: ["slides"] });
    },
  });

  const handleDeleteCourse = () => {
    mutate(courseId);
  };

  return (
    <>
      <YesOrNoDialog
        heading="هل انت متأكد من رغبتك في الحذف !؟"
        description=" لأنه لا يمكنك التراجع او استرجاع اي بيانات فيما بعد هل انت متأكد فعلا لا يمكنك التراجع فيما بعد لذلك ارجوك كن حذرا مع تلك العملية"
        functiontoDo={handleDeleteCourse}
        openDialog={openDialog}
        closeFunction={handleClose}
      />
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "visible",
          p: 1,
        }}
      >
        <MDBox position="relative" width="100.25%" shadow="xl" borderRadius="xl">
          {showDefaultImage || !isLoaded ? (
            <CardMedia
              src={logo}
              onLoad={() => setIsLoaded(true)}
              component="img"
              title={courseName}
              sx={{
                width: "100%",
                height: "200px",
                margin: 0,
                boxShadow: ({ boxShadows: { md } }) => md,
                objectFit: "fill",
                objectPosition: "center",
                opacity: showDefaultImage ? 1 : 0,
                transition: "opacity 0.6s ease-in-out",
              }}
            />
          ) : null}

          <CardMedia
            src={image}
            onLoad={() => setIsLoaded(true)}
            component="img"
            title={courseName}
            sx={{
              width: isLoaded && !showDefaultImage ? "100%" : "0",
              height: isLoaded && !showDefaultImage ? "200px" : "0",
              margin: 0,
              boxShadow: ({ boxShadows: { md } }) => md,
              objectFit: "cover",
              objectPosition: "center",
              opacity: isLoaded && !showDefaultImage ? 1 : 0,
              transition: "opacity 0.6s ease-in-out",
            }}
          />
        </MDBox>
        <MDBox mt={1} mx={0.5}>
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
          >
            {teacherName} {stage ? " - " + stage : ""}
          </MDTypography>
          <MDBox mb={1}>
            {action.type === "internal" ? (
              <MDTypography
                component={Link}
                to={action.route}
                variant="h5"
                textTransform="capitalize"
              >
                {courseName}
              </MDTypography>
            ) : (
              <MDTypography
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                textTransform="capitalize"
              >
                {courseName}
              </MDTypography>
            )}
          </MDBox>
          <MDBox mb={2} lineHeight={0}>
            <CourseStack
              numberOfLectures={numberOfLectures}
              ratingsAverage={ratingsAverage}
              color="black"
              fontSize="medium"
            />
          </MDBox>
          <MDBox display="flex" justifyContent="start" alignItems="center" mb={1}>
            {action.type === "internal" ? (
              <MDButton
                component={Link}
                to={action.route}
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </MDButton>
            ) : (
              <MDButton
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color={action.color}
              >
                {action.label}
              </MDButton>
            )}

            <MDButton
              variant="outlined"
              size="small"
              color="error"
              sx={{ marginInlineStart: "0.4rem" }}
              onClick={() => setOpenDialog(true)}
              disabled={isLoading}
            >
              حذف الكورس
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

// Typechecking props for the CourseCard
CourseCard.propTypes = {
  image: PropTypes.string.isRequired,
  teacherName: PropTypes.string.isRequired,
  // stage: PropTypes.string,
  courseName: PropTypes.string.isRequired,

  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CourseCard;
