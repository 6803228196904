// @mui material components
import { useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import { useMaterialUIController, setSuccessMSG } from "context";

import { useMutation, useQuery } from "react-query";
import { AddSlide } from "utils/apis/home";
import { GetCourses } from "utils/apis/courses";
import MDButton from "components/MDButton";

import useCourses from "hooks/useCourses";
import useStages from "hooks/useStages";

function SlideInfo() {
  const [controller, dispatch] = useMaterialUIController();
  const [courseId, setCourseId] = useState();
  const { stagesData } = useStages();
  const [sortNumber, setSortNumber] = useState();
  const [selectedStageId, setSelectedStageId] = useState(null);
  const navigate = useNavigate();

  const { mutate, isError, isLoading, error } = useMutation({
    mutationFn: AddSlide,
    onSuccess: (response) => {
      navigate("/home/slides");
      setSuccessMSG(dispatch, "تمت عملية اضافة السلايد بنجاح");
    },
  });

  const handleAdd = () => {
    mutate({ course_id: courseId, sort_number: Number(sortNumber), stage_id: selectedStageId });
  };

  const { courses: AllCoursesForSelect } = useCourses();

  const stageChange = (event, value) => {
    setSelectedStageId(value?.id || null);
  };

  return (
    <MDBox>
      <MDTypography variant="h5">السلايد الجديد</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3} flexDirection="column">
          <Grid item xs={12}>
            <Autocomplete
              options={AllCoursesForSelect}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => setCourseId(value.id)}
              renderInput={(params) => (
                <MDInput {...params} label="الدورة التعليمية" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={stagesData || []}
              getOptionLabel={(option) => option.name}
              onChange={stageChange}
              renderInput={(params) => (
                <MDInput {...params} label="المرحلة الدراسية" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MDInput
              type="number"
              label="التسلسل"
              variant="outlined"
              sx={{ width: "100%" }}
              onChange={(e) => setSortNumber(e.target.value)}
            />
          </Grid>
          {isError && (
            <Grid item xs={12}>
              <MDTypography
                variant="caption"
                display="block"
                color="error"
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {error?.response?.data?.message}
              </MDTypography>
            </Grid>
          )}
          <Grid item xs={12} container justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => handleAdd()}
              disabled={isLoading}
            >
              سلايد جديد
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default SlideInfo;
