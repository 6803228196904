/** 
  All of the routes for the Otis Admin PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Otis Admin PRO React layouts
import Slides from "layouts/Home/Slides";
import StagesAndSubjects from "layouts/Home/StagesAndSubjects";
import SpecialCourses from "layouts/courses/SpecialCourses";
import NewCourse from "layouts/courses/NewCourse";
import Teachers from "layouts/users/Teachers";

// import SignInBasic from "layouts/authentication/sign-in/basic";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";

// Otis Admin PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/profile-image.png";
// <MDAvatar src={profilePicture} alt="Super Admin" size="sm" />
const routes = [
  {
    type: "collapse",
    name: "مدير النظام",
    key: "brooklyn-alice",
    icon: <Icon fontSize="medium">person</Icon>,
    // collapse: [
    //   {
    //     name: "Settings",
    //     key: "profile-settings",
    //     route: "/pages/account/settings",
    //     component: <Slides />,
    //   },
    //   {
    //     name: "Logout",
    //     key: "logout",
    //     route: "/authentication/sign-in",
    //     component: <SignInBasic />,
    //   },
    // ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "الصفحة الرئيسية",
    key: "home",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "السلايدات",
        key: "slides",
        route: "/home/slides",
        component: <Slides />,
      },
      {
        name: "مراحل و مواد",
        key: "stages-and-subjects",
        route: "/home/stages-and-subjects",
        component: <StagesAndSubjects />,
      },
    ],
  },

  {
    type: "collapse",
    name: "الكورسات",
    key: "courses",
    icon: <Icon fontSize="medium">view_timeline</Icon>,
    collapse: [
      {
        name: "كورس جديد",
        key: "new-course",
        route: "courses/new-course",
        component: <NewCourse />,
      },
      {
        name: "الكورسات المميزة",
        key: "special-courses",
        route: "courses/special-courses",
        component: <SpecialCourses />,
      },
    ],
  },

  {
    type: "collapse",
    name: "المستخدمين",
    key: "users",
    icon: <Icon fontSize="medium">group</Icon>,
    collapse: [
      {
        name: "المدرسين",
        key: "teachers",
        route: "/teachers",
        component: <Teachers />,
      },
    ],
  },

  { type: "divider", key: "divider-1" },
];

export default routes;
