import axiosInstance from "utils/axiosInstance";
import { createFile } from "utils/methods";

export const GetTeachers = async () => {
  const response = await axiosInstance.get("/api/dashboard/teachers");

  return response;
};

export const DeleteTeacher = async (teacherId) => {
  const response = await axiosInstance.delete(`/api/dashboard/teachers/${teacherId}`);
  return response;
};

export const AddTeacher = async (teacher) => {
  const formData = new FormData();
  formData.append("file", teacher?.file);
  formData.append("name", teacher?.name);
  formData.append("bio", teacher?.bio);
  const response = await axiosInstance.post("/api/dashboard/teachers", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

// export const EditTeacher = async (teacherObj, id) => {
//   const response = await axiosInstance.patch(`/api/dashboard/teachers/${id}`, teacherObj);

//   return response;
// };

export const EditTeacher = async (teacherObj, id) => {
  const formData = new FormData();

  if (teacherObj?.file instanceof File) {
    formData.append("file", teacherObj?.file);
  } else {
    const newFile = await createFile(teacherObj?.file);
    formData.append("file", newFile);
  }
  formData.append("name", teacherObj?.name);
  formData.append("bio", teacherObj?.bio);
  const response = await axiosInstance.patch(`/api/dashboard/teachers/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};
